// extracted by mini-css-extract-plugin
export var sectionTop = "produkte-module--section-top--COLhH";
export var imageCarousel = "produkte-module--image-carousel--2hR1S";
export var headline = "produkte-module--headline--yMPf8";
export var label = "produkte-module--label--cVWgY";
export var topGrid = "produkte-module--top-grid--1JPv7";
export var topGridItemImage = "produkte-module--top-grid-item-image--EjJOo";
export var topGridItemHeadline = "produkte-module--top-grid-item-headline--2Qequ";
export var topGridItemWidget = "produkte-module--top-grid-item-widget--RH5_E";
export var produktText = "produkte-module--produktText--3SoN3";
export var benefits = "produkte-module--benefits--1bJLL";
export var benefitsWrapper = "produkte-module--benefits-wrapper--QgMrM";
export var benefitsItem = "produkte-module--benefits-item--35Xk2";
export var detail = "produkte-module--detail--1pleY";
export var spinAround = "produkte-module--spinAround--3hfJN";