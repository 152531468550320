import { graphql } from "gatsby"
import Layout, { IMetadata } from "../components/Layout"
import React from "react"
import Section from "../components/ui/Section/Section";
import { GatsbyImage } from "gatsby-plugin-image";
import ProductWidget from "../components/ProductWidget/ProductWidget";
import IProdukt from "../interfaces/IProdukt";
import ProductImage from "../components/product/ProductImage";
import * as styles from "./produkte.module.scss";
import BreakpointRender from "../utils/BreakpointRender";
import Carousel from "../components/ui/Carousel/Carousel";
import classnames from 'classnames';
import Blocks from "../components/blocks/Blocks";
import Star from "../assets/star.inline.svg";
import AnchorMenu from "../components/blocks/AnchorMenu";
import useGdprIsAllowed from "../components/Gdpr/useGdprIsAllowed";

export default ({ data, location }) => {
    const product: IProdukt = data.produkteHJson;

    // Tracking Product Page View to Matomo
    const trackingAllowed = useGdprIsAllowed("Analyse");
    React.useEffect(() => {
            if (trackingAllowed && _paq) {
                _paq.push(['setEcommerceView',
                    product.url, // (required) SKU: Product unique identifier
                    product.name, // (optional) Product name
                    product.produktTyp, // (optional) Product category, or array of up to 5 categories
                ]);

                // _paq.push(['trackPageView']);
            } 
    });

    const breadCrumbLookUp = function (url: string, name: string): Array<{label: string; url: string}> {

        // BREADCRUMB STUFF
        const crumbs: Array<string> = url.split("/");
        let output: Array<{label: string; url: string}> = [];
        switch (crumbs[0]) {
            case "textilien":
                output.push({ label: "Textilien", url: "textilien"});
                break;
            case "schilder":
                output.push({ label: "Schilder", url: "schilder"});
                break;
            case "aufkleber":
                output.push({ label: "Aufkleber", url: "aufkleber"});
                break;
            case "zubehoer":
                output.push({ label: "Zubehör", url: "zubehoer"});
                break;
            case "werbetechnik":
                output.push({ label: "Werbetechnik", url: "werbetechnik" });
                break;

        }

        output.push({ label: name, url: crumbs[1]});

        return output;
    };

    const crumbs = [
        { label: "Home", url: "" },
        { label: "Produkte", url: "produkte" },
        ...breadCrumbLookUp(product.url, product.name),
    ];

    // END BREADCRUMB

    // Create metadata object
    const metaData: IMetadata = {};
   if (product.meta) { 
       if (product.meta.description) {
            metaData.description = product.meta.description;
        }
        if (product.meta.title) {
            metaData.title = product.meta.title;
        }
        if (product.meta.ogImage) {
            metaData.ogImage = product.meta.ogImage.childImageSharp.gatsbyImageData.images.fallback.src;
        }
        if (product.meta.ogTitle) {
            metaData.ogTitle = product.meta.ogTitle;
        }
        if (product.meta.ogDescription) {
            metaData.ogDescription = product.meta.ogDescription;
        }
    }

    return (
        <Layout crumbs={crumbs} location={location} metadatas={metaData}>
            
            <Section className={styles.sectionTop}>
                <div className={styles.topGrid}>

                    <div className={styles.topGridItemHeadline}>
                        <div className="content">
                            <h1 className={classnames(["title", "has-hyphens", styles.headline])}>{product.name}</h1>
                            <p>{product.kurzbeschreibung}</p>
                        </div>
                    </div>

                    <div className={styles.topGridItemImage}>
                            <div className={classnames(["is-hidden-desktop", styles.imageCarousel])}>
                                <Carousel startIndex={0} variant="fullwidth">
                                    { [
                                        <ProductImage key={"hauptbild"}product={product} />,
                                        ...(product.bilder ? product.bilder.map((image, imageKey) => <div  key={imageKey} ><GatsbyImage
                                            image={image.src.childImageSharp.gatsbyImageData}
                                            className={styles.detail}
                                            alt={image.alt}
                                            title={image.alt} /></div>) : []),
                                    ]}
                                </Carousel>
                            </div>

                            <BreakpointRender visibleOn={["desktop"]}>
                                <div className="columns">
                                    {product.bilder && <div className="column is-one-quarter is-hidden-mobile">
                                        {product.bilder.map((image, imageKey) => <div  key={imageKey} ><GatsbyImage
                                            image={image.src.childImageSharp.gatsbyImageData}
                                            className={styles.detail}
                                            alt={image.alt}
                                            title={image.alt} /></div>)}
                                    </div>}

                                    <div className="column">
                                        <ProductImage product={product} />
                                    </div>
                                </div>
                            </BreakpointRender>

                    </div>


                    <div className={styles.topGridItemWidget}>
                        <ProductWidget product={product} />
                    </div>
                </div>
                
            </Section>

            <div className="blocks">
                <Section size="extraSmall">
                    <AnchorMenu blocks={[
                        { title: "Produktinformationen", type: "section",},
                        ...(product.blocks ? product.blocks.filter((item) => item.title) : []),
                    ]} />
                </Section>

                <Section id="produktinformationen" size="small">
                    <div className="columns is-variable">
                        <div className="column">
                            <div className="content">
                                <h3>Produktinformationen</h3>
                                <div className={styles.produktText} dangerouslySetInnerHTML={{__html: product.produktText}}>
                                </div>
                            </div>
                        </div>
                        <div className="column is-1"></div>
                        <div className="column is-one-third-desktop is-hidden-mobile">
                            <div className={classnames([styles.benefits])}>
                                <h3>Vorteile</h3>
                                <ul className={styles.benefitsWrapper}>
                                    {product.vorteile && <>{product.vorteile.map((benefit, key) => <li key={key} className={styles.benefitsItem}>{benefit}</li>)}</>}
                                    <li className={styles.benefitsItem}>Hohe Qualität</li>
                                    <li className={styles.benefitsItem}>Schnelle Lieferung</li>
                                    <li className={styles.benefitsItem}>Professionelle und persönliche Beratung</li>
                            </ul>
                            </div>
                        </div>
                    </div>

                </Section>

                <Section size="small">
                    <div className="columns">
                        <div className="column">
                            {product.infos.map((item, infoKey) => (
                                <div key={infoKey} className="columns  is-gapless">
                                    <div className="column is-one-third">
                                        <span className={styles.label}>{item.beschriftung}</span>
                                    </div>
                                    <div className="column">
                                        {item.wert}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="column is-1"></div>
                        <div className="column is-hidden-mobile  is-one-third-desktop">
                            {product.bewertung.map((item, bewertungKey) => (
                                <div key={bewertungKey} className="columns is-mobile">
                                    <div className="column">
                                        <span className={styles.label}>{item.typ}</span>
                                    </div>
                                    <div className="column is-narrow">
                                        {[...Array(item.stars)].map((i, iKey) => <Star style={{width: 22, height: 22, marginRight: 10}} key={iKey} />)}
                                        {[...Array(5 - item.stars)].map((i, iKey) => <Star style={{width: 22, height: 22, opacity: 0.44, marginRight: 10}} key={iKey} />)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Section>
            </div>

            {product.blocks && <Blocks blocks={product.blocks} />}
            
        </Layout>
    );
}

export const productQuery = graphql`query ($url: String!) {
  produkteHJson(url: {eq: $url}) {
    name
    meta {
      title
      description
      ogTitle
      ogImage {
        childImageSharp {
          gatsbyImageData(width: 300, layout: FIXED)
        }
      }
      ogDescription
    }
    bewertung {
      stars
      typ
    }
    vorteile
    productWidgetKey
    filters {
      typ
      wert
      filter
      widget
      widgetType
      defaultValue
    }
    infos {
      beschriftung
      wert
    }
    kurzbeschreibung
    produktText
    produktTyp
    url
    hauptbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    listenbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    bilder {
      alt
      src {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    blocks {
      ...BlocksFragment
    }
  }
}
`
