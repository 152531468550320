import React from 'react';
import IProdukt from '../../interfaces/IProdukt';
import { GatsbyImage } from "gatsby-plugin-image";

type Props = {
    product: IProdukt;
}
export default (props: Props) => {
    const {product} = props;

    // const { forms } = React.useContext(ProductWidgetContext);

    // const images= useStaticQuery(graphql`
    //     query {
    //         allFile(filter: {sourceInstanceName: {eq: "data"}, relativePath: {regex: "/varianten/"}}) {
    //         edges {
    //             node {
    //                 sourceInstanceName
    //                 name
    //                 childImageSharp {
    //                     fluid {
    //                         ...GatsbyImageSharpFluid_withWebp
    //                     }
    //                 }
    //             }
    //         }
    //         }
    //     }
    // `);

    // const selectedWidgetValues = forms[product.produktTyp];

    // let matchedFile = product.hauptbild.src;
    // let matchedValueNumber = 2;
    // for (const {node} of images.allFile.edges) {
    //     let matched = 0;
    //     for ( const value of node.name.split("--")) {
    //         for (const widgetValue in selectedWidgetValues) {
    //             let wv: string = String(selectedWidgetValues[widgetValue].value);
    //             if (value.toLowerCase() === wv.toLowerCase()) {
                    
    //                 matched++;
    //             }
    //         }
    //     }
    //     if (matched > matchedValueNumber) {
    //         matchedFile = node;
    //         matchedValueNumber = matched;
    //     }
    // }
    
    return <>
        <GatsbyImage
            image={{...product.hauptbild.src.childImageSharp.gatsbyImageData, aspectRatio: 1.25}}
            alt={product.hauptbild.alt}
            title={product.hauptbild.alt} 
        />
    </>;

}